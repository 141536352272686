import { makeAutoObservable } from 'mobx'

export default class Coupon {
  id

  title

  shortDescription

  details

  image

  disclaimer

  value

  GAData = {
    coupon_id: null,
    value: null,
    category_description: null,
    brand: null,
    giq_category_description: null,
    top_level_category_description: null,
  }

  constructor({
    coupon_id,
    value,
    offer_summary_top,
    offer_summary_detail,
    image_url_suffix,
    offer_disclaimer,
    category_description,
    brand,
    giq_category_description,
    top_level_category_description,
  }) {
    makeAutoObservable(this)
    const [couponTitle] = offer_summary_top ? offer_summary_top.split(' ') : []
    const couponDesc = `${offer_summary_top} ${offer_summary_detail}`
    this.id = coupon_id
    this.value = value
    this.title = couponTitle
    this.details = offer_summary_detail
    this.image = `/coupons/${image_url_suffix}`
    this.disclaimer = offer_disclaimer
    this.shortDescription = couponDesc

    this.GAData = {
      coupon_id,
      value,
      category_description,
      brand,
      giq_category_description,
      top_level_category_description,
    }
  }
}
