import staticRoutes from '../../config/router.config'
import LoginStore from '../components/UserManagement/Login-store'
import { standardEventForCampaignLoad } from '../containers/AuthContainer/standardEventErrorLog'

export const showAddToHomeLoggedInView = () => {
  let addToHomeVisitCount = localStorage.getItem('addToHomeVisitCount')
    ? Number(localStorage.getItem('addToHomeVisitCount'))
    : 0
  let status = false
  let status1 = false
  if (addToHomeVisitCount < 2) {
    addToHomeVisitCount += 1
    localStorage.setItem('addToHomeVisitCount', addToHomeVisitCount)
    sessionStorage.setItem('homeInSession', 'true')
    status = false
  } else if (addToHomeVisitCount >= 2 && addToHomeVisitCount < 5) {
    addToHomeVisitCount += 1
    localStorage.setItem('addToHomeVisitCount', addToHomeVisitCount)
    sessionStorage.setItem('homeInSession', 'true')
    status = true
  } else if (addToHomeVisitCount >= 5 && addToHomeVisitCount < 8) {
    addToHomeVisitCount += 1
    status1 = true
    localStorage.setItem('addToHomeVisitCount', addToHomeVisitCount)
    sessionStorage.setItem('homeInSession', 'true')
  }
  return {
    LayoutStatus: status,
    hamburgerStatus: status1,
  }
}
export const calculateDateDifference = (startDate, endDate) => {
  const start = new Date(startDate).getTime()
  const end = new Date(endDate).getTime()
  const milliseconds = end - start.toString()
  const seconds = parseInt(milliseconds / 1000, 10)
  const minutes = parseInt(seconds / 60, 10)
  const hours = parseInt(minutes / 60, 10)
  const days = parseInt(hours / 24, 10)
  const date = {
    days,
    hours,
    minutes,
    seconds,
  }
  return date
}
export const restrictedPagesForCompleteProfileOverlay = () => {
  const {
    myAccount,
    completeProfile,
    rewardsOptout,
    changePassword,
    pointsDetails,
    digitalCoupons,
    pointsHistory,
    offerrules,
    help,
    signup,
    forgotPassword,
    myMessages,
    sitemap,
    referFriend,
    chatLink,
    survey,
    impactSummary,
    receiptScan,
    search,
    info,
    login,
    maintenance,
    sustainabilityMythQuiz,
    rebates,
    rebatesWithEndSlash,
    brands,
    manageCoupons,
    authError,
    sustainability,
    impact,
    About,
    rewards,
    accountSetupPage,
  } = staticRoutes

  const pageArr = [
    myAccount,
    completeProfile,
    digitalCoupons,
    rewardsOptout,
    changePassword,
    pointsDetails,
    pointsHistory,
    offerrules,
    help,
    signup,
    forgotPassword,
    myMessages,
    sitemap,
    referFriend,
    chatLink,
    survey,
    impactSummary,
    receiptScan,
    search,
    info,
    login,
    maintenance,
    sustainabilityMythQuiz,
    rebates,
    rebatesWithEndSlash,
    brands,
    manageCoupons,
    authError,
    sustainability,
    impact,
    About,
    rewards,
    accountSetupPage,
  ]
  return pageArr
}

export const simplifiedSignupFlowImplementedPages = () => {
  const {
    rebatesWithEndSlash,
  } = staticRoutes

  const pageArr = [
    rebatesWithEndSlash,
  ]
  return pageArr
}

export const redirectToEarnPointsAfterLogin = () => {
  const pageArr = [
    'rewards',
    'save-and-earn',
    'out-of-stock',
    'restocking',
    'receipt-scan',
  ]
  return pageArr
}

export const simplifiedSignupFlowImplementedPagesWith20Survey = () => {
  const {
    couponsWithEndSlash,
  } = staticRoutes

  const pageArr = [
    couponsWithEndSlash,
  ]
  return pageArr
}
export const handleCountryRedirection = () => {
  LoginStore.showCountryRestrictionModal = false
  window.location = 'https://www.pg.com/'
}

export async function checkIFrameLoad(url, successCode, errorCode) {
  fetch(url, { method: 'HEAD' }).then((response) => {
    if (response.ok) {
      standardEventForCampaignLoad(successCode, url)
    } else {
      standardEventForCampaignLoad(errorCode, url)
    }
  }).catch((error) => {
    standardEventForCampaignLoad(errorCode, { url, error })
  })
}
