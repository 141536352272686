import { makeAutoObservable } from 'mobx'

export default class Coupon {
  id

  title

  details

  image

  smartphoneImage

  brand

  value

  GAData = {
    incentive_id: null,
    value: null,
    brand: null,
    shortDesc: null,
  }

  constructor({
    products,
    distributions,
    description,
    additionalInfo,
    name,
    endDate,
    incentiveId,
    incentiveValue,

  }) {
    makeAutoObservable(this)
    const formatedincentiveValue = incentiveValue.toFixed(2)
    this.id = Number(incentiveId)
    this.value = `${'$'}${formatedincentiveValue}`
    this.title = name
    this.shortDesc = `${'$'}${formatedincentiveValue} ${description[0].value}`
    this.details = description[0].value
    this.infoDisclaimer = additionalInfo ? additionalInfo[0].value : null
    this.image = distributions[0].websiteImage
    this.smartphoneImage = distributions[0].mobileImage
    this.brand = products[0].brandName
    this.expiry = endDate
    this.GAData = {
      incentive_id: incentiveId,
      value: incentiveValue,
      shortDesc: description[0].value,
      brand: products[0].brandName,
    }
  }
}
