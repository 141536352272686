import { makeAutoObservable } from 'mobx'
import { fetchCouponRestrictionApi } from '../../helpers/api.helper'
import { fetchNationalCoupons, storageAvailable } from '../../adapters/serverside.adapters'
import Coupon from './Models/Coupon.model'
import { setDataLayerCouponObject } from '../../helpers/gaEvent.helper'
import LoginStore from '../../components/UserManagement/Login-store'
import { throwAIMIAError } from '../../okta/forms/okta.helpers'
import { standardEventForCouponsAddtoBasket } from '../AuthContainer/standardEventErrorLog'
import { STATUS_1110, STATUS_1111 } from '../../constants/status-code'

const CLOUDINARY_MAPPED_URL = 'http://cdn.cpnscdn.com/insight.coupons.com/COS20/_Cache/_ImageCache/'

// this is a temporary coupon will be removed once SIT is Done for Dawn Sweepstakes
const dummyCoupons = []

class CouponsStore {
  constructor() {
    makeAutoObservable(this)
  }

  printID = null

  coupons = []

  couponsToDisplay = []

  couponsNotAvailable = false

  basket = []

  validImage = false

  imageSuffix = null

  postPrintPopup = false

  addToBasketPendingWithLogin = false

  oneTimePromtForAddToBasket = false

  postLoginOneTimeAddPrompt = false

  get printIframeURL() {
    const couponsToPrint = this.basket.map((coupon) => coupon.id).join()
    return `${process.env.QUOTIENT_PRINT_IFRAME}${couponsToPrint}`
  }

  init = async (hiddenCoupons) => {
    if (storageAvailable('localStorage')) {
      const localGuid = LoginStore.profileData?.traitGUID?.traitValue || localStorage.getItem('localGuid') || null
      if (localGuid && localGuid !== 'G' && localStorage.getItem('loggedIn')) {
        this.printID = localGuid
      } else this.printID = null
    }

    const response = await fetchNationalCoupons()
    const allCoupons = await response?.json()

    if (response?.status !== 200) {
      LoginStore.showErrorModal = true
      throwAIMIAError('fetchNationalCoupons', allCoupons)
    }
    allCoupons.offers = [...allCoupons.offers, ...dummyCoupons]
    this.couponsNotAvailable = allCoupons.offers.length === 0
    const restrictedCoupons = this.printID
      ? await fetchCouponRestrictionApi(this.printID)
      : { restrictions: [] }
    this.validImage = allCoupons.image_url_prefix === CLOUDINARY_MAPPED_URL
    this.imageSuffix = allCoupons.image_url_prefix
    this.coupons = allCoupons?.offers
      ?.map((couponData) => new Coupon(couponData.offer))
      ?.filter(
        (eachCoupon) => !restrictedCoupons.restrictions.find(
          (restricted) => restricted.coupon_id === eachCoupon.id,
        ),
      )
      .sort((a, b) => b.value - a.value)

    if (storageAvailable('sessionStorage') && sessionStorage.getItem('brandName')) {
      const brandFilteredArray = this.coupons?.filter((item) => item
      && item.GAData
      && item.GAData.brand
      && item.GAData.brand.toUpperCase().includes(sessionStorage.getItem('brandName').toUpperCase()))
      const notBrandFilteredArray = this.coupons?.filter((item) => item
      && item.GAData
      && item.GAData.brand
      && !item.GAData.brand.toUpperCase().includes(sessionStorage.getItem('brandName').toUpperCase()))
      this.coupons = [...brandFilteredArray, ...notBrandFilteredArray]
    }

    if (hiddenCoupons && hiddenCoupons.length > 0) {
      this.coupons = this.coupons.filter(
        (coupon) => !hiddenCoupons.find(
          (hide) => coupon.id === hide.couponId,
        ),
      )
    }
    this.populateBasketFromLocalStorage()
    setDataLayerCouponObject(this.basket)
    return this.coupons
  }

  addToBasket = (couponId) => {
    const newCoupon = this.coupons?.filter((coupon) => coupon.id === couponId)[0]
    try {
      if (newCoupon && !this.basket.includes(newCoupon)) this.basket.push(newCoupon)
      setDataLayerCouponObject(this.basket)
      this.updateCouponsInLocalSotrage()
      standardEventForCouponsAddtoBasket(
        STATUS_1111,
        LoginStore?.profileData?.consumerId,
        { couponId },
      )
    } catch (err) {
      standardEventForCouponsAddtoBasket(
        STATUS_1110,
        LoginStore?.profileData?.consumerId,
        { couponId, error: err },
      )
      LoginStore.setErrorModalCode(STATUS_1110)
    }
  }

  removeFromBasket = (couponId) => {
    if (typeof sessionStorage !== 'undefined' && (sessionStorage.getItem('couponIdAddToCart1') || sessionStorage.getItem('couponIdAddToCart2')) && (parseInt(sessionStorage.getItem('couponIdAddToCart1'), 10) === couponId || parseInt(sessionStorage.getItem('couponIdAddToCart2'), 10) === couponId)) {
      sessionStorage.setItem('OfferExpired', true)
    }
    this.basket = this.basket?.filter((coupon) => coupon.id !== couponId)
    this.updateCouponsInLocalSotrage()
  }

  emptyBasket = () => {
    const basketContains = localStorage.getItem('basketItems') || '[]'
    this.basket = JSON.parse(basketContains).map((couponId) => this.removeFromBasket(couponId))
    localStorage.setItem('basketItems', '[]')
  }

  updateCouponsInLocalSotrage = () => {
    const basketItems = this.basket?.length !== 0 ? this.basket?.map(({ id }) => id) : []
    localStorage.setItem('basketItems', JSON.stringify(basketItems))
  }

  populateBasketFromLocalStorage = () => {
    if (storageAvailable('localStorage')) {
      const basketFromLocaleStorage = localStorage.getItem('basketItems') !== 'undefined' ? JSON.parse(localStorage.getItem('basketItems')) : []
      this.basket = this.coupons?.filter(({ id }) => {
        for (
          let index = 0;
          index < basketFromLocaleStorage?.length;
          index += 1
        ) {
          const basketItemId = basketFromLocaleStorage[index]
          if (basketItemId === id) return true
        }
        return false
      })
      this.updateCouponsInLocalSotrage()
    }
  }
}
export default new CouponsStore()
