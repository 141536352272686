import Router from 'next/router'
import LoginStore from './login-screen.store'

export const getLoginCount = () => {
  if (typeof localStorage !== 'undefined') {
    const splashCount = localStorage.getItem('loginCount')
      ? parseInt(localStorage.getItem('loginCount'), 10)
      : 0
    return splashCount
  }
  return 0
}

export const setLoginCount = () => {
  const splashCount = localStorage.getItem('loginCount')
    ? parseInt(localStorage.getItem('loginCount'), 10)
    : 0
  if (splashCount > 3) return

  localStorage.setItem('loginCount', splashCount + 1)
}
export const setPageVisitCount = () => {
  if (typeof localStorage !== 'undefined') {
    const noOfPagaVisitCount = localStorage.getItem('pageVisitCount')
      ? parseInt(localStorage.getItem('pageVisitCount'), 10)
      : 0

    localStorage.setItem('pageVisitCount', noOfPagaVisitCount + 1)
  }
}
export const getPageVisitCount = () => {
  if (typeof localStorage !== 'undefined') {
    const noOfPagaVisitCount = localStorage.getItem('pageVisitCount')
      ? parseInt(localStorage.getItem('pageVisitCount'), 10)
      : 0

    return noOfPagaVisitCount
  }
  return null
}
export const setSiteVisitCount = () => {
  if (typeof localStorage !== 'undefined') {
    const noOfSiteVisitCount = localStorage.getItem('siteVisitCount')
      ? parseInt(localStorage.getItem('siteVisitCount'), 10)
      : 0

    localStorage.setItem('siteVisitCount', noOfSiteVisitCount + 1)
  }
  return null
}
export const getSiteVisitCount = () => {
  if (typeof localStorage !== 'undefined') {
    const noOfSiteVisitCount = localStorage.getItem('siteVisitCount')
      ? parseInt(localStorage.getItem('siteVisitCount'), 10)
      : 0

    return noOfSiteVisitCount
  }
  return null
}

export const handleConfirmationModalClose = () => {
  if (LoginStore.pendingFor) {
    Router.push(LoginStore.pendingFor)
  } else if (!LoginStore.checkLoginStatus) {
    Router.push('/')
  }
  localStorage.removeItem('alreadyRegisteredEmail')
}
