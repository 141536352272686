import { makeAutoObservable } from 'mobx'
import { getdigitalCoupons, storageAvailable } from '../../adapters/serverside.adapters'
import Coupon from './digitalCoupon.model'
import { setDataLayerCouponObject } from '../../helpers/gaEvent.helper'
import LoginStore from '../../components/UserManagement/Login-store'
import { throwAIMIAError } from '../../okta/forms/okta.helpers'
import { standardEventForCouponsAddtoBasket } from '../AuthContainer/standardEventErrorLog'
import { STATUS_1218, STATUS_1219 } from '../../constants/status-code'

class DigitalCouponsStore {
  constructor() {
    makeAutoObservable(this)
  }

  incentives = null

  coupons = []

  couponsToDisplay = []

  couponsNotAvailable = false

  basket = []

  validImage = false

  imageSuffix = null

  postPrintPopup = false

  addToBasketPendingWithLogin = false

  oneTimePromtForAddToBasket

  showOtpPopup = false

  mobileNumber = ''

  refreshCoupons = false

  clippedCouponerrorScreen = false

  searchData = []

  partialSuccessModal = false

  clippedCouponsCount = null

  clippingFailedCount = null

  clippingFailedStatusInCart = false

  changedMobilenumberinTcb = false

  wrongInputMobileNumberTCB = false

  openAccordian

  totalCouponsLength = 0

  succesfullyConnectedModal = false

  sendDownloadLinkPendingwithLogin = false

  linkMobileNumberToAccountPopup = ''

  linkAccountFor = ''

  currentlyConnectedAPP = ''

  linkAccountPendingwithLogin = false

  linkingOneAppToAnotherApp = false

  postLoginOneTimeAddPrompt = false

  fetchdigitalCoupons = async () => {
    try {
      const response = await getdigitalCoupons()
      const data = await response.json()
      if (response.status !== 200) {
        LoginStore.showErrorModal = true
        throwAIMIAError('fetchdigitalCoupons', data)
      }
      this.couponsToDisplay = data?.data?.incentives
        ?.filter(
          (eachCoupon) => eachCoupon?.incentiveId
          !== eachCoupon?.consumerIncentive?.incentiveId,
        ).filter(
          (eachCoupon) => eachCoupon?.distributions[0].availableQuantity
        > 0,
        )
        .map((couponData) => new Coupon(couponData)) || []
      this.totalCouponsLength = data?.data?.incentives.length
      this.populateBasketFromLocalStorage()
    } catch (error) {
      this.couponsToDisplay = []
    }
  }

  addToBasket = async (couponId) => {
    try {
      if (LoginStore.rewardStatus) {
        if (this.couponsToDisplay.length === 0) return
        const newCoupon = await this.couponsToDisplay.filter(
          (coupon) => coupon.id === couponId,
        )[0]
        if (!this.basket.includes(newCoupon) && newCoupon !== undefined) {
          this.basket.push(newCoupon)
          setDataLayerCouponObject(this.basket)
          this.updatedigitalCouponsInLocalSotrage()
          this.clippedCouponerrorScreen = false
        } else {
          this.clippedCouponerrorScreen = true
        }
        standardEventForCouponsAddtoBasket(
          STATUS_1219,
          LoginStore?.profileData?.consumerId,
          { couponId },
        )
      }
    } catch (err) {
      standardEventForCouponsAddtoBasket(
        STATUS_1218,
        LoginStore?.profileData?.consumerId,
        { couponId, error: err },
      )
      LoginStore.setErrorModalCode(STATUS_1218)
    }
  }

  removeFromBasket = (couponId) => {
    if (
      typeof sessionStorage !== 'undefined'
      && (sessionStorage.getItem('couponIdAddToCart1')
        || sessionStorage.getItem('couponIdAddToCart2'))
      && (parseInt(sessionStorage.getItem('couponIdAddToCart1'), 10)
        === couponId
        || parseInt(sessionStorage.getItem('couponIdAddToCart2'), 10) === couponId)
    ) {
      sessionStorage.setItem('OfferExpired', true)
    }
    this.basket = this.basket.filter((coupon) => coupon.id !== couponId)
    this.updatedigitalCouponsInLocalSotrage()
  }

  updatedigitalCouponsInLocalSotrage = () => {
    const digitalbasketItems = this.basket.length !== 0 ? this.basket.map(({ id }) => id) : []
    localStorage.setItem(
      'digitalbasketItems',
      JSON.stringify(digitalbasketItems),
    )
  }

  populateBasketFromLocalStorage = () => {
    if (storageAvailable('localStorage')) {
      const basketFromLocaleStorage = JSON.parse(localStorage.getItem('digitalbasketItems')) || []
      this.basket = this.couponsToDisplay?.filter(({ id }) => {
        for (
          let index = 0;
          index < basketFromLocaleStorage.length;
          index += 1
        ) {
          const basketItemId = basketFromLocaleStorage[index]
          if (basketItemId === id) return true
        }
        return false
      })
      this.updatedigitalCouponsInLocalSotrage()
    }
  }
}

export default new DigitalCouponsStore()
