import { makeAutoObservable } from 'mobx'

class LoginScreenStore {
  constructor() {
    makeAutoObservable(this)
  }

  formErrorResponse = {}

  get isInvalidForm() {
    return !!this.formErrorResponse.form
  }
}

export default new LoginScreenStore()
